// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here

@import "_child_template_parts";

$font-stack: 'Nunito', sans-serif;

/* Mobile */

@media screen and (max-width: 767px) {
	.navbar-collapse {
		text-align: center;
		font-size: 22px;
	}
	.hide-mobile {
		display: none;
	}
}

/* Fonts */

body {
	font-family: $font-stack;
	overflow-x: hidden;
	background: #fff;
}

@media screen and (max-width: 767px) {
	html, body {
		max-width: 100%;
		overflow-x: hidden;
	}
}

p {
	font-size: 20px;
}
u {

}
.entry-content, p, a {
	font-family: $font-stack;
}
.lightblue {
	color: #007baf;
}
.darkblue {
	color: #0d4079;
}
.maintitle {
	font-family: $font-stack;
	font-size: 60px;
	line-height: 60px;
	@media screen and (max-width: 1420px) {
		font-size: 50px;
		line-height: 50px;
	}
	@media screen and (max-width: 1140px) {
		font-size: 45px;
		line-height: 45px;
	}
	@media screen and (max-width: 768px) {
		font-size: 40px;
		line-height: 40px;
	}
}
.maintitle2 {
	font-family: $font-stack;
	font-size: 35px;
	line-height: 35px;
	font-weight: 600;
	@media screen and (max-width: 1420px) {
		font-size: 28px;
		line-height: 28px;
	}
	@media screen and (max-width: 1140px) {
		font-size: 25px;
		line-height: 25px;
	}
}
.maintitle3 {
	font-family: $font-stack;
	font-size: 28px;
	line-height: 28px;
	font-weight: 600;
	@media screen and (max-width: 768px) {
		font-size: 26px;
		line-height: 26px;
	}
}

.serviceicon,  {
	width: 90px;
	height: 78px;
	fill: #0d4079 !important;
}
.planicon {
	width: 95px;
	height: 95px;
	margin: 25px 0 0 0;
	fill: #0d3f78 !important;
}

/* Body */

.container-fluid {
	padding-left: 15px !important;
	padding-right: 15px !important;
}
@media screen and (max-width: 768px) {
	.container {
		padding-right: 25px;
		padding-left: 25px;
	}
}
a {
	outline: 0;
}

@media screen and (min-width: 576px) {
	.container {
		/*max-width: 100% !important;*/
	}
}

/* Header */

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

.navbar {
	padding: 0;
	margin: 0;
}
#wrapper-navbar {
	color: #fff;
	position: sticky;
	top: 0;
	z-index: 99999;
	background: $darkblue;
	border-bottom: 2px solid $lightblue;
}
#wrapper-navbar a {
    padding: 5px 15px;
	margin: 1px 2px;
	color: #fff;
	@media screen and (max-width: 1000px) {
		padding: 5px 5px 8px 5px;
		font-size: 12px;
	}
}
#wrapper-navbar a:hover, #wrapper-navbar a.active {
    background: #fff;
    color: $darkblue !important;
}
@media screen and (max-width: 1000px) and (min-width: 768px) {
	.navbar-collapse {
		text-align: center;
		font-size: 18px;
	}
	#wrapper-navbar a {
		padding: 5px 5px;
	}
}
.dropdown-menu {
	margin-top: 0px;
	padding: 4px 4px;
	background: $darkblue !important;
	border: 0;
	border-radius: 0;
	border-bottom-left-radius: .25rem !important;
	border-bottom-right-radius: .25rem !important;
	border-bottom: 2px solid $lightblue;
	left: 2px;
}
.dropdown:hover .dropdown-menu {
	display: block;
}
.dropdown-toggle::after {
	display: none;
}
.dropdown-item {
	margin: 0 !important;
	border-radius: 0;
}
.navbar-toggler-icon {
	color: #333 !important;
	background: #fff;
	border-top-left-radius: 95%;
    border-bottom-right-radius: 95%;
    border-top-right-radius: 5%;
    border-bottom-left-radius: 5%;
}
.dropdownsection h2 {
	font-weight: 600;
}

#wrapper-navbar {
	padding: 0px;
}
.navbar-brand:hover {
	background: transparent !important;
}
.navbar-brand .logo1 {
	margin-top: -28px;
	transition: all 0.6s;
	max-height: 55px;
}
.navbar-brand .logo2 {
	margin-left: 65px;
	margin-top: -26px;
	transition: all 0.5s;
	max-height: 50px;
}
.navbar-brand img {
	position: absolute;
	width: auto;
}

#wrapper-navbar a {
	font-weight: bold;
}
@-moz-document url-prefix() {
	#wrapper-navbar .nav-link, #wrapper-navbar .navbar-brand {
		font-family: 'Montserrat', sans-serif !important;
	}
}

.the-top-bar a:hover {
	color: #fff;
}
.top-bar-link {
	margin: 0 10px; color: #fff;
	@media screen and (max-width: 420px) {
		margin: 0 2px; color: #fff;
	}
}
.top-bar-link-last {
	margin: 0 0 0 10px; color: #fff;
	@media screen and (max-width: 420px) {
		margin: 0 0 0 2px; color: #fff;
	}
}

.header-icon {
	font-size: 12.5px;
	padding: 0 10px;
	color: #fff;
	&:hover {
		color: #fff;
	}
	@media screen and (max-width: 420px) {
		display: none !important;
	}
}

/* Footer */

#wrapper-footer {
	background: $darkblue;
	border-top: 2px solid $lightblue;
	color: #fff;
	margin-top: 0px;
	padding: 15px 0;
}

.footer-links {
	padding: 60px 0px;
	p {
		font-size: 16px;
		margin: 10px 0;
	}
	h5 {
		width: 100%;
		padding-bottom: 10px;
		border-bottom: 2px solid #f3f3f3;
	}
	.footercol {
		padding: 0 20px;
		@media screen and (max-width: 768px) {
			padding: 15px 20px;
		}
	}
}
.footer-icons {
	width: 100%; font-size: 20px; text-align: center; padding: 0;
}
.footer-icon {
	padding: 0 10px;
	color: #fff;
	&:hover {
		color: #fff;
	}
}

/* Body */

#full-width-page-wrapper {
	padding: 0;
}

.popover, .tooltip-inner {
    max-width: 100%;
    /* If max-width does not work, try using width instead */
    width: 380px;
	background: rgba(255,255,255,1) !important;
	color: $darkblue !important;
	padding: 0px !important;
	border: 2px solid $lightblue;
	font-size: 16px;
	text-align: center;
	&:hover {
		display: block;
	}
	p {
		margin: 0;
	}
}
.popover-header {
	background: $darkblue;
	color: #fff;
	text-align: center;
	font-weight: bold;
	font-size: 18px;
	padding: 10px 20px;
	border-radius: 2px;
	
}

.animatebg {
	background: linear-gradient(-45deg, $lightblue, $darkblue, $lightblue, $darkblue);
	background-size: 400% 400%;
	-webkit-animation: Gradient 7s ease infinite;
	-moz-animation: Gradient 7s ease infinite; 
	animation: Gradient 7s ease infinite;
}

/* Buttons */

.nicebutton {
	font-family: 'Montserrat', sans-serif !important;
	background: $darkblue;
	border-bottom: 2px solid $lightblue;
	padding: 15px 40px;
	color: #fff;
	font-size: 20px;
	&:hover {
		text-decoration: none;
		color: #fff;
		background: $lightblue;
	}
}

/* Home */

.homemainservice {
	width: 120px;
	height: 120px;
	margin-bottom: 20px;
}
.audit-home-box {
	border-top: 2px solid #007baf;
}

/* Table */

.privacytable {
	width: 100% !important;
}
.privacytable td {
	padding: 6px;
	border: 2px solid #f3f3f3;
}

/* Contact */

.contactform {
	input, textarea {
		width: 100%;
		padding: 10px;
		border-radius: 5px;
		border: 2px solid #f3f3f3;
	}
	.submitform {
		font-weight: bold;
		color: #fff;
	}
}

/* Ribbons */

	/* common */
	.ribbon {
	  width: 150px;
	  height: 150px;
	  overflow: hidden;
	  position: absolute;
	}
	.ribbon::before,
	.ribbon::after {
	  position: absolute;
	  z-index: -1;
	  content: '';
	  display: block;
	  border: 5px solid #2980b9;
	}
	.ribbon span {
	  position: absolute;
	  display: block;
	  width: 225px;
	  padding: 15px 0;
	  background-color: #3498db;
	  box-shadow: 0 5px 10px rgba(0,0,0,.1);
	  color: #fff;
	  font: 700 18px/1 'Lato', sans-serif;
	  text-shadow: 0 1px 1px rgba(0,0,0,.2);
	  text-transform: uppercase;
	  text-align: center;
	}

	/* top left*/
	.ribbon-top-left {
	  top: -10px;
	  left: -10px;
	}
	.ribbon-top-left::before,
	.ribbon-top-left::after {
	  border-top-color: transparent;
	  border-left-color: transparent;
	}
	.ribbon-top-left::before {
	  top: 0;
	  right: 0;
	}
	.ribbon-top-left::after {
	  bottom: 0;
	  left: 0;
	}
	.ribbon-top-left span {
	  right: -25px;
	  top: 30px;
	  transform: rotate(-45deg);
	}

	/* top right*/
	.ribbon-top-right {
	  top: -10px;
	  right: -10px;
	}
	.ribbon-top-right::before,
	.ribbon-top-right::after {
	  border-top-color: transparent;
	  border-right-color: transparent;
	}
	.ribbon-top-right::before {
	  top: 0;
	  left: 0;
	}
	.ribbon-top-right::after {
	  bottom: 0;
	  right: 0;
	}
	.ribbon-top-right span {
	  left: -25px;
	  top: 30px;
	  transform: rotate(45deg);
	}

	/* bottom left*/
	.ribbon-bottom-left {
	  bottom: -10px;
	  left: -10px;
	}
	.ribbon-bottom-left::before,
	.ribbon-bottom-left::after {
	  border-bottom-color: transparent;
	  border-left-color: transparent;
	}
	.ribbon-bottom-left::before {
	  bottom: 0;
	  right: 0;
	}
	.ribbon-bottom-left::after {
	  top: 0;
	  left: 0;
	}
	.ribbon-bottom-left span {
	  right: -25px;
	  bottom: 30px;
	  transform: rotate(225deg);
	}

	/* bottom right*/
	.ribbon-bottom-right {
	  bottom: -10px;
	  right: -10px;
	}
	.ribbon-bottom-right::before,
	.ribbon-bottom-right::after {
	  border-bottom-color: transparent;
	  border-right-color: transparent;
	}
	.ribbon-bottom-right::before {
	  bottom: 0;
	  left: 0;
	}
	.ribbon-bottom-right::after {
	  top: 0;
	  right: 0;
	}
	.ribbon-bottom-right span {
	  left: -25px;
	  bottom: 30px;
	  transform: rotate(-225deg);
	}
