/* General */

.title1 {
	font-size: 65px;
	line-height: 65px;
}
.title2 {
	font-size: 85px;
	line-height: 85px;
	font-weight: 600;
}
.title3 {
	font-size: 35px;
	line-height: 35px;
	font-weight: 600;
}
	@media screen and (max-width: 1600px) {
		.title1 {
			font-size: 50px;
			line-height: 50px;
		}
		.title2 {
			font-size: 70px;
			line-height: 80px;
		}
		.title3 {
			font-size: 25px;
			line-height: 25px;
		}
	}
	@media screen and (max-width: 1380px) {
		.title1 {
			font-size: 40px;
			line-height: 40px;
		}
		.title2 {
			font-size: 60px;
			line-height: 60px;
		}
		.title3 {
			font-size: 20px;
			line-height: 20px;
		}
	}
	@media screen and (max-width: 720px) {
		.title1 {
			font-size: 30px;
			line-height: 30px;
		}
		.title2 {
			font-size: 40px;
			line-height: 40px;
		}
		.title3 {
			font-size: 20px;
			line-height: 20px;
		}
	}
.screen-padding {
	@media screen and (max-width: 1840px) {
		padding-left: 100px !important;
		padding-right: 100px !important;
	}
	@media screen and (max-width: 1680px) {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	@media screen and (max-width: 1280px) {
		padding-left: 10px !important;
		padding-right: 10px !important;
		padding-top: 30px !important;
		padding-bottom: 30px !important;
		margin: 20px 0 !important;
	}
	@media screen and (max-width: 720px) {
		padding-left: 10px !important;
		padding-right: 10px !important;
		padding-top: 35px !important;
		padding-bottom: 5px !important;
		margin: 10px 0 !important;
	}
}

.white_button {
	display: block;
	width: 100%;
	margin: 15px auto 15px auto !important;
	padding: 10px 10px;
	border: 2px solid $darkblue;
	border-radius: 5px;
	text-decoration: none;
	background: #fff;
	color: $darkblue;
	font-family: 'Montserrat', sans-serif !important;
	&:hover {
		background: $lightblue;
		color: #fff;
	}
}
.custom_button {
	display: block;
	width: 80%;
	margin: 15px auto 25px auto !important;
	padding: 10px 10px;
	border: 2px solid $darkblue;
	border-radius: 5px;
	text-decoration: none;
	background: linear-gradient(-45deg, #007baf, #0d4079, #007baf, #0d4079);
	background-size: 400% 400% !important;
	-webkit-animation: Gradient 7s ease infinite !important;
	-moz-animation: Gradient 7s ease infinite !important;
	animation: Gradient 7s ease infinite !important;
	color: #fff;
	font-weight: bold;
	font-family: 'Montserrat', sans-serif !important;
	&:hover {
		background: $lightblue;
		color: #fff;
		text-decoration: none;
	}
	&.pricing_button {
		background: linear-gradient(-45deg,#3C8E4A,#43CA5A,#3C8E4A,#43CA5A);
		text-shadow: 0px 0px 3px #3C8E4A;
		text-decoration: none;
	}
}
.button_gradient {
	display: block;
	padding: 10px 10px;
	border: 2px solid $darkblue;
	border-radius: 5px;
	text-decoration: none;
	background: linear-gradient(-45deg, #007baf, #0d4079, #007baf, #0d4079);
	background-size: 400% 400% !important;
	-webkit-animation: Gradient 7s ease infinite !important;
	-moz-animation: Gradient 7s ease infinite !important;
	animation: Gradient 7s ease infinite !important;
	color: #fff;
	font-weight: bold;
	font-family: 'Montserrat', sans-serif !important;
	&:hover {
		background: $lightblue;
		color: #fff;
		text-decoration: none;
	}
}

/************************/
/***** LARGE HEADER *****/
/************************/

.large_main_header { 
	padding-left: 200px;
	padding-right: 200px;
	@media screen and (max-width: 1840px) {
		padding-left: 100px;
		padding-right: 100px;
	}
	@media screen and (max-width: 1680px) {
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 220px !important;
		margin-bottom: 60px;
	}
	@media screen and (max-width: 1280px) {
		padding-top: 40px !important;
		padding-bottom: 180px !important;
	}
}

@media screen and (max-width: 1620px) {
	.header-main-right {
		margin-top: -80px;
	}
}
@media screen and (max-width: 767px) {
	.header-main-right {
		display: none;
	}
}

.animate-cloud1 {
	-webkit-animation:linear infinite alternate;
	-webkit-animation-name: movecloud1;
	-webkit-animation-duration: 6s;
}
@-webkit-keyframes movecloud1 {
    0% { left: 0%;}
    50%{ left: 4%;}
    100%{ left: 0%;}
}

.animate-cloud2 {
	-webkit-animation:linear infinite alternate;
	-webkit-animation-name: movecloud2;
	-webkit-animation-duration: 3s;
}
@-webkit-keyframes movecloud2 {
    0% { left: -4%;}
    100%{ left: 4%;}
}

/**************************/
/***** LARGE CONTENT *****/
/**************************/

.large_content_box {
	input {
		width: 100%;
		padding: 10px 20px;
		text-align: center;
		border-radius: 5px;
	}
	#mc-embedded-subscribe {
		background: linear-gradient(-45deg, $lightblue, $darkblue, $lightblue, $darkblue);
		background-size: 400% 400%;
		-webkit-animation: Gradient 7s ease infinite;
		-moz-animation: Gradient 7s ease infinite;
		animation: Gradient 7s ease infinite;
		color: #fff;
		font-weight: bold;
		&:hover {
			background: $lightblue;
		}
	}
	@media screen and (max-width: 1220px) {
		.col-lg-9 {
			width: 100% !important;
			max-width: 100% !important;
			flex: 0 0 100% !important;
		}
	}
}


/* Large Content Box Tel */
.tel {
    width: 250px;
    margin: 0 auto;
    background: red;
	border: 3px solid #333;
    font-size: 24px;
    line-height: 24px;
    padding: 20px 0;
    text-align: center;
    font-family: 'Londrina Solid', cursive;
    color: #fff;
}
.tel a {
    color: #fff;
	font-weight: bold;
	text-decoration: underline;
}


/**********************/
/***** HEADER BOX *****/
/**********************/

.header_box {
	margin-bottom: 30px;
	.after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
	}
}

/**************************/
/***** CONTENT BLOCKS *****/
/**************************/

/* large_content_box */

/* left_box_right_image */

.lbri_left_col {
	background-image: linear-gradient(#1a96d4, #0E87C4);
	background-size: cover; 
	color: #fff;
	@media screen and (max-width: 768px) {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
}
.lbri_left_text {
	@include media-breakpoint-up(lg) {
		float: right;
	}
	@include media-breakpoint-up(lg) {
		padding: 0px !important;
	}
}
.lbri_right_bg {
	background-size: cover !important;
	background-position: top right !important;
	@media screen and (max-width: 768px) {
		margin-bottom: 0px;
	}
}

/*****************/
/***** BOXES *****/
/*****************/

.custom_boxes {
	@media screen and (max-width: 576px) {
		padding: 2% 0;
	}
}
.custom_boxes_box_section {
	padding: 10px;
	margin: 0 1%;
	text-align: center;
	transition: all 1s ease-in-out;
	@media screen and (max-width: 1000px) {
		width: 48% !important;
		margin: 1% 1%;
	}
	@media screen and (max-width: 576px) {
		width: 98% !important;
		margin: 1% 1%;
	}
	&:hover {
		transform: scale(1.04);
		transition: all 0.3s ease-in-out;
	}
}
.custom_boxes_box_section img {
	margin-bottom: 10px;
	width: 140px;
	border-radius: 50%;
	border: 2px solid #fff;
}
.custom_boxes_box_section .box-button {
	padding: 10px 0;
	width: 90%;
	text-align: center;
	border: 2px solid #fff;
	text-transform: uppercase;
	display: inline-block;
	bottom: 20px;
	position: absolute;
	left: 5%;
	color: #fff;
	font-weight: bold;
	font-family: 'Montserrat', sans-serif !important;
	&:hover {
		background: #fff;
		color: #000;
		text-decoration: none;
	}
}

/****************************/
/***** THREE ICON BOXES *****/
/****************************/

.three_icon_box_col_section {
	font-family: Londrina Solid;
	padding: 10px;
	margin: 0 1%;
	text-align: center;
	transition: all 1s ease-in-out;
	@media screen and (max-width: 576px) {
		width: 98% !important;
		margin: 1% 0;
	}
	&:hover {
		transform: scale(1.04);
		transition: all 0.3s ease-in-out;
	}
}
#three_icon_box_section .box-button {
	padding: 10px 0;
	width: 300px;
	max-width: 100%;
	text-align: center;
	text-transform: uppercase;
	display: block;
	margin: 20px auto;
	font-weight: bold;
	font-size: 25px;
	font-family: 'Montserrat', sans-serif !important;
	&:hover {
		background: #fff;
		color: #000;
		text-decoration: none;
	}
}
.three_icon_box_icon {
	font-size: 90px;
	i {
		font-size: 90px;
	}
	img {
		width: 100%;
		max-width: 200px;
		margin-bottom: 10px;
	}
}

/***********************/
/***** NUMBER COLS *****/
/***********************/

.number_col_section {
	font-family: Londrina Solid;
	padding: 10px;
	margin: 0 5%;
	text-align: center;
	@media screen and (max-width: 576px) {
		width: 98% !important;
		margin: 1% 0;
	}
	.col-number {
		font-family: Londrina Solid;
		font-weight: bold;
		font-size: 60px;
		border-bottom: 2px solid #fff;
	}
	.col-number-text {
		font-size: 20px;
	}
}

/************************/
/***** BUTTON BOXES *****/
/************************/

.button_box_a {
	padding: 10px 10px;
	width: 80% !important;
	margin: 0 auto;
	display: block;
	border: 2px solid #fff;
	font-size: 25px;
	color: #fff;
	text-decoration: none;
	font-family: 'Montserrat', sans-serif !important;
	transition: all 0.5s ease-in-out; 
	&:hover {
		color: #fff;
		transform: scale(1.03);
		text-decoration: none;
	}
	@media screen and (max-width: 1680px) {
		width: 80% !important;
	}
	@media screen and (max-width: 1140px) {
		width: 50% !important;
	}
	@media screen and (max-width: 560px) {
		width: 85% !important;
	}
}

/********************/
/***** LOCATION *****/
/********************/

.location-field {
	text-align: center;
	padding: 15px 15px;
	width: 60%;
	display: block;
	margin: 10px auto;
	font-size: 20px;
	border: 2px solid #333;
	outline: 0 !important;
}
.location-submit {
	padding: 8px 10px;
	width: 60%;
	display: block;
	margin: 10px auto;
	background: #1a96d4;
	color: #fff;
	font-size: 20px;
	border: 2px solid #333;
}
#locationsuccess p {
	text-align: center;
	padding: 0px;
	font-size: 16px;
}
#locationsuccess input {
	text-align: center;
	padding: 0px;
	font-size: 16px;
	min-height: 30px !important;
}
#locationsuccess .wpcf7-form p {
	margin-bottom: 8px;
}

/**********************/
/***** POSTS LIST *****/
/**********************/

.plist_box {
	padding: 20px 20px;
	margin: 10px;
	border: 2px dotted #f3f3f3;
}
.plist_box .understrap-read-more-link {
	background: #1a96d4;
}
.pagination {
	color: #fff;
	a {
		color: #fff;
	}
	span, a {
		background: #306ebd;
		padding: 5px 10px;
		margin-right: 5px;
		margin-top: 5px;
	}
}

/***************/
/***** FAQ *****/
/***************/

.accordion#faqarea {
	border: 0;
	width: 95%;
	margin: 0 auto;
	.card {
		margin: 15px 0;
		border: 0;
		border: 2px solid #f3f3f3;
		.card-body {
			border-top: 2px solid #f3f3f3;
			padding: 10px 20px 0px 20px;
		}
	}
}
.faqheader {
	text-decoration: none !important;
	&:hover {
		background: $lightblue;
		color: #fff;
	}
	h4 {
		margin: 0;
	}
}
[aria-expanded~="true"]:not(.collapsed) {
	background: $lightblue;
	color: #fff;
}
.faqs .card-header {
	padding: 10px 20px;
}
.faqs .panel {
	margin: 10px 0 !important;
}
.faqs p {
	font-size: 16px;
}



/**************************/
/***** SLIDER SECTION *****/
/**************************/

.slider_section {
	padding: 0 120px;
	@media screen and (max-width: 720px) {
		padding: 0 15px;
	}
}
.slick-slide h2{
  text-align:center;
  padding: 20px;
}
/* Slider */

.slick-slide {
    margin: 0px 20px;
}

.slick-slide img {
    width: 100%;
}

.slick-slider
{
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;
    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/*****************************/
/***** SERVICES SECTIONS *****/
/*****************************/

.services_section {
	.service_cols {
		[class*='fa'] {
			font-size: 80px;
		}
	}
	.service_box_section_text {
		margin-top: 10px;
		p {
			margin-bottom: 0;
		}
	}
	.service_box_area {
		padding: 10px 15px;
		transition: all 0.2s ease-in-out;
	}
	.service_box_area:hover {
		background: #FAFAFA;
		transform: scale(1.02);
		box-shadow: 0px 0px 2px #DADADA;
	}
	.service_cols_link {
		text-decoration: none;
		display: contents;
	}
}

/*******************/
/***** PRICING *****/
/*******************/

col.pricing_section {
	@media screen and (max-width: 728px) {
		margin: 0 15px;
	}
}

.pricing_section {
	padding: 0;
	margin: 0 10px;
	text-align: center;
	background: #fff;
	box-shadow: 0px 0px 5px #333;
	border-bottom: 8px solid $lightblue;
	transition: all 0.5s ease-in-out;
	img {
		transition: all 0.3s ease-in-out;
	}
	transform: scale(1.001);
	&:hover {
		transform: scale(1.015);
		img {
			
		}
	}
		@media screen and (max-width: 1140px) {
			width: 48%;
			min-width: 48%;
			margin: 1%;
		}
		@media screen and (max-width: 720px) {
			width: 98% !important;
			min-width: 100%;
			margin: 1%;
		}
	.pricing_content {
		padding: 10px;
	}
	.pricing_title {
		font-weight: bold;
		font-size: 30px;
		line-height: 50px;
		border-bottom: 2px solid #fff;
	}
	.pricing_description {
		font-size: 14px;
		line-height: 20px;
		color: #333;
	}
	.pricing_price {
		font-size: 45px;
		line-height: 45px;
		font-weight: bold;
	}
	.pricing_period {
		margin-bottom: 0;
	}
	.pricing_list_item {
		font-size: 15px;
		line-height: 16px;
	}
}
[data-original-title] {
	
}
.pricing_tooltip {
	color: $lightblue;
	text-decoration: none;
	&:hover {
		color: $darkblue;
	}
}

.pricing_toggles {
	text-align: center;
	margin-bottom: 25px;
	.pricing_toggle {
		padding: 5px 15px; 
		margin: 0 5px;
		border-radius: 5px;
		font-weight: bold;
		cursor: pointer;
		max-width: 100%;
		white-space: initial;
		font-family: 'Montserrat', sans-serif !important;
	}
}
#hideshow_yearly, #hideshow_monthly, #hideshow_hosting, #hideshow_nohosting {
	background-size: 400% 400% !important;
	-webkit-animation: Gradient 7s ease infinite !important;
	-moz-animation: Gradient 7s ease infinite !important;
	animation: Gradient 7s ease infinite !important;
}


/********************/
/***** INFO BOX *****/
/********************/

.info_box_area {
	border-bottom-left-radius: 30px;
	border-top-right-radius: 30px;
	box-shadow: 0px 0px 10px #333;
	p {
		font-size: 16px !important;
	}
}

/*******************/
/***** CLIENTS *****/
/*******************/

.clientbox {
	text-align: center;
	padding: 10px 10px;
}